import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { Ripple } from 'vuetify/lib/directives';
import { en, ro } from '@/locale';

// Autoload custom components
import VDialogContainer from './components/v-dialog-container.vue';
import VCustomDataTable from './components/v-custom-data-table.vue';

Vue.component('v-dialog-container', VDialogContainer);
Vue.component('v-custom-data-table', VCustomDataTable);

// Options for vuetify
Vue.use(Vuetify, {
  directives: {
    Ripple
  }
});

export default new Vuetify({
  lang: {
    locales: {
      en,
      ro
    }
  },
  theme: {
    themes: {
      dark: {
        primary: '7209B7'
      },
      light: {
        primary: '7209B7'
      }
    }
  }
});
