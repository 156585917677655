import axios, { AxiosResponse, AxiosError, Axios, Method } from 'axios';
import router from '@/router';
import store from '@/store';

class Http extends Axios
{
  constructor()
  {
    super();

    const token = function (): string {
      if (store) {
        const att: string = store.getters.accessTokenType;
        const at: string = store.getters.accessToken;
        if (att && at) {
          return att + ' ' + at;
        }
      }

      return '';
    };

    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };
    if (token()) {
      Object.assign(headers, { 'Authorization': token() });
    }

    const axiosApiInstance = axios.create();
    axiosApiInstance.interceptors.request.use(
      (config) => {
        config.baseURL = process.env.VUE_APP_BACKEND;
        config.headers = Object.assign(config.headers, headers);
        config.params = Object.assign(config.params || {}, { 'hl': store.getters.locale });

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    axiosApiInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && originalRequest.url.includes('/v1/account/refresh-login')) {
          store.commit('clearUserData');
          await router.push({
            name: 'login',
            query: { redirect: '/' }
          });
        } else if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          await store.dispatch('refreshToken', '/v1/account/refresh-login');
          if (token()) {
            Object.assign(headers, { 'Authorization': token() });
          }

          return axiosApiInstance(originalRequest);
        }

        return Promise.reject(error);
      }
    );

    return axiosApiInstance;
  }
}

export type HttpResponse = AxiosResponse;
export type HttpError = AxiosError;
export type HttpMethod = Method;

export default Http;
