import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import appModule from '@/store/modules/app';
import authModule from '@/store/modules/auth';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState()
  ],
  modules: {
    app: appModule,
    auth: authModule
  }
});
