
/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-var-requires, @typescript-eslint/ban-ts-comment */
import Vue from 'vue';
import { PropValidator } from 'vue/types/options';
import { DataGroupFunction, DataPagination, DataSortFunction } from 'vuetify/types';
// @ts-ignore
import { groupItems, sortItems } from 'vuetify/lib/util/helpers';
import { Header, ItemProps } from '@/types/DataTable';
import { each } from '@/utils/helpers';
import intl from '@/utils/intl';

import DataTableFilters from '@/components/data-table/data-table-filters.vue';
import DataTableResizeCol from '@/components/data-table/data-table-resize-col.vue';

export default Vue.extend({
  name: 'VCustomDataTable',
  components: {
    DataTableFilters,
    DataTableResizeCol
  },
  props: {
    allowColumnResizing: {
      type: Boolean,
      default: false
    },
    columnSelector: {
      type: Boolean,
      default: true
    },
    customGroup: {
      type: Function,
      default: groupItems
    } as PropValidator<DataGroupFunction>,
    customSort: {
      type: Function,
      default: sortItems
    } as PropValidator<DataSortFunction>,
    dense: Boolean,
    disablePagination: {
      type: Boolean,
      default: false
    },
    disableSort: {
      type: Boolean,
      default: false
    },
    groupBy: {
      type: [String, Array],
      default: () => []
    } as PropValidator<string | string[]>,
    groupDesc: {
      type: [Boolean, Array],
      default: () => []
    } as PropValidator<boolean | boolean[]>,
    fixedHeader: {
      type: Boolean,
      default: false
    },
    headers: {
      type: Array,
      default: () => []
    } as PropValidator<Header[]>,
    hideDefaultFooter: {
      type: Boolean,
      default: false
    },
    hideDefaultHeader: {
      type: Boolean,
      default: false
    },
    itemKey: String,
    items: {
      type: Array,
      default: () => []
    } as PropValidator<any[]>,
    itemsPerPage: {
      type: Number,
      default: 10
    },
    loading: {
      type: Boolean,
      default: false
    },
    locale: {
      type: String,
      default: ''
    },
    page: {
      type: Number,
      default: 1
    },
    search: String,
    showExpand: {
      type: Boolean,
      default: false
    },
    showGroupBy: {
      type: Boolean,
      default: false
    },
    showSelect: {
      type: Boolean,
      default: false
    },
    showFilters: {
      type: Boolean,
      default: false
    },
    singleExpand: {
      type: Boolean,
      default: false
    },
    singleSelect: {
      type: Boolean,
      default: false
    },
    sortBy: {
      type: [String, Array]
    } as PropValidator<string | string[] | undefined>,
    sortDesc: {
      type: [Boolean, Array]
    } as PropValidator<boolean | boolean[] | undefined>,
    value: {
      type: Array,
      default: () => []
    } as PropValidator<any[]>,
    wrapperStyles: String
  },
  data() {
    return {
      filterItems: [] as Array<any>,
      selectedHeaders: [] as Array<string>,
      tableHeaders: [] as Array<Header>,
      internalGroupBy: Array.isArray(this.groupBy) ? this.groupBy : [this.groupBy] as Array<string>
    }
  },
  computed: {
    model: {
      get(): any {
        return this.value;
      },
      set(value: any): void {
        this.$emit('input', value);
      }
    },
    classes(): Record<string, boolean> {
      return {
        'v-custom-data-table': true,
        'resizable': this.allowColumnResizing
      }
    },
    menuHeaders(): Array<Header> {
      return this.headers
        .filter(item => item.text && item.value !== 'data-table-expand')
        .filter(item => !this.internalGroupBy.includes(item.value));
    },
    hasReloadEvent(): boolean {
      return 'click:reload' in this.$listeners;
    },
    groupByText(): string {
      return this.tableHeaders.find((header: Header) => header.value === this.internalGroupBy[0])?.text ?? '';
    },
    columnSelectorButton(): string {
      return intl.t('dataTable.columnSelector');
    },
    reloadButton(): string {
      return intl.t('dataTable.reloadText');
    }
  },
  methods: {
    clickReload(): void {
      this.$emit('click:reload');
    },
    clickRow(item: any, data: ItemProps): void {
      this.$emit('click:row', item, data);
    },
    itemExpanded(item: any, value: boolean): void {
      this.$emit('item-expanded', item, value);
    },
    itemSelected(item: any, value: boolean): void {
      this.$emit('item-selected', item, value);
    },
    pageCount(value: number): void {
      this.$emit('page-count', value);
    },
    pagination(value: DataPagination): void {
      this.$emit('pagination', value);
    },
    updateGroupBy(item: string | string[]): void {
      this.$emit('update:group-by', item);
    },
    updateGroupDesc(item: string | string[]): void {
      this.$emit('update:group-desc', item);
    },
    updateOptions(item: any): void {
      this.internalGroupBy = item.groupBy || [];
      if (this.showGroupBy) {
        this.updateGroupByHeaders();
      }
      this.$emit('update:options', item);
    },
    updateSortBy(item: string | string[]): void {
      this.$emit('update:sort-by', item);
    },
    updateSortDesc(item: string | string[]): void {
      this.$emit('update:sort-desc', item);
    },
    filterResult(items: Array<any>): void {
      this.filterItems = items;
    },
    // Custom
    updateGroupByHeaders(): void {
      this.$nextTick(() => {
        each(this.$el.querySelectorAll('table thead.v-data-table-header th'), (th: HTMLTableElement, idx: number) => {
          const span = [...th.querySelectorAll('span')]
            .filter((span: HTMLSpanElement) => span.innerText === 'group')
            .shift();
          if (span) {
            if (this.singleSelect && idx === 0) {
              span.remove();
            } else {
              span.title = 'group';
              span.setAttribute('class', 'header-group v-icon notranslate mdi mdi-plus-box');
              span.setAttribute('style', 'font-size:18px; height:18px; width:18px');
              span.setAttribute('aria-hidden', 'true');
              span.innerHTML = '';
            }
          }
        });
      });
    }
  },
  watch: {
    headers: {
      handler(data: Array<Header>): void {
        this.tableHeaders = [];
        this.selectedHeaders = [];
        each(data, (header: Header) => {
          if (header.hidden !== true) {
            this.tableHeaders.push(header);
            this.selectedHeaders.push(header.value);
          }
        });
      },
      immediate: true
    },
    showGroupBy: {
      handler(value: boolean): void {
        if (value) {
          this.updateGroupByHeaders();
        }
      },
      immediate: true
    },
    wrapperStyles: {
      handler(value: string): void {
        // Set Data Table Wrapper styles
        this.$nextTick(() => {
          const wrapper = Array.from(this.$el.children).find(item => item.className.includes('v-data-table__wrapper')) as HTMLElement;
          if (value && wrapper) {
            wrapper.setAttribute('style', value.trim());
          }
        });
      },
      immediate: true
    },
    selectedHeaders(data: Array<string>): void {
      let headers: Array<Header> = [];
      each(data, (val: string) => {
        const header = this.headers.find(item => item.value == val);
        if (header) {
          headers.push(header);
        }
      });
      headers = headers.sort((a: Header, b: Header) => {
        const indexA = this.headers.findIndex(item => item.value == a.value);
        const indexB = this.headers.findIndex(item => item.value == b.value);

        return indexA - indexB;
      });
      this.tableHeaders = [];
      this.tableHeaders = headers;
      this.$emit('update:selectedHeaders', data);
      if (this.showGroupBy) {
        this.updateGroupByHeaders();
      }
    },
    '$vuetify.breakpoint.smAndUp'(value: boolean): void {
      if (this.showGroupBy && value) {
        this.updateGroupByHeaders();
      }
    }
  }
});
