
import Vue from 'vue';
import { Tenant } from '@/types/Tenant';
import Http, { HttpError } from '@/utils/Http';
import { empty, each } from '@/utils/helpers';

export default Vue.extend({
  name: 'TenantPanel',
  props: {
    tenant: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      menu: false,
      tenantId: this.tenant.tenant_guid,
      tenantName: this.tenant.tenant_name,
      tenantsLoading: false,
      tenants: {} as Record<string, string>
    }
  },
  watch: {
    async menu(status: boolean): Promise<void> {
      this.tenants = {};
      if (status) {
        this.tenantsLoading = true;
        try {
          const response = await (new Http()).get(`/v1/tenants-user`);
          if (!empty(response.data.data)) {
            const data = response.data.data.filter((item: Tenant) => item.tenant_guid !== this.tenantId);
            if (data.length > 0) {
              each(data, (item: Tenant) => {
                this.tenants[item.tenant_guid] = item.tenant_name;
              });
            } else {
              this.tenants[this.tenantId] = this.tenantName;
            }
          }
        } catch (error) {
          const err = (error as HttpError).response?.data.error;
          this.$root.$children[0].$children[0].setFlash('error', err.message);
        }
        this.tenantsLoading = false;
      }
    }
  },
  methods: {
    async changeTenant(id: string): Promise<void> {
      this.tenantsLoading = true;
      try {
        const response = await (new Http()).put(`/v1/user/change-tenant`, JSON.stringify({ tenant:id }));
        if (!empty(response.data)) {
          const data = response.data;
          this.$store.commit('setLoggedUser', data);
          await this.$nextTick();
          if (data.tenant) {
            this.tenantId = data.tenant.tenant_guid;
            this.tenantName = data.tenant.tenant_name;
          }
        }
        this.menu = false;
        location.reload();
      } catch (error) {
        const err = (error as HttpError).response?.data.error;
        this.$root.$children[0].$children[0].setFlash('error', err.message);
      }
      this.tenantsLoading = false;
    }
  }
});
