import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor';

Vue.use(VueMonacoEditorPlugin, {
  paths: {
    // The recommended CDN config
    //vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs'
  }
});
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
