
import Vue from 'vue';

export default Vue.extend({
  name: 'DataTableResizeCol',
  data() {
    return {
      currentTh: null as HTMLElement | null,
      nextTh: null as HTMLElement | null,
      currentOffsetStart: 0,
      nextOffsetStart: 0,
      minWidth: 90
    }
  },
  methods: {
    getPageX(e: MouseEvent | TouchEvent): number {
      if (e instanceof MouseEvent) {
        return Math.floor(e.pageX);
      } else {
        return Math.floor(e.changedTouches[0].pageX);
      }
    },
    setCurrent(width: number): void {
      if (this.currentTh) {
        this.currentTh.setAttribute('width', `${width}`);
        this.currentTh.style.width = `${width}px`;
        this.currentTh.style.minWidth = `${width}px`;
        this.$emit('updateWidth', width);
      }
    },
    setNext(width: number): void {
      if (this.nextTh) {
        this.nextTh.setAttribute('width', `${width}`);
        this.nextTh.style.width = `${width}px`;
        this.nextTh.style.minWidth = `${width}px`;
      }
    },
    start(e: MouseEvent | TouchEvent): void {
      e.stopPropagation();

      this.currentTh = (e.target as HTMLElement).parentElement;
      if (this.currentTh == null) return;
      this.currentOffsetStart = this.currentTh.offsetWidth - this.getPageX(e);
      this.nextTh = this.currentTh.nextElementSibling as HTMLElement | null;
      if (this.nextTh) {
        this.nextOffsetStart = this.nextTh.offsetWidth + this.getPageX(e);
      }

      document.addEventListener('mousemove', this.move);
      document.addEventListener('touchmove', this.move);
      document.addEventListener('mouseup', this.stop);
      document.addEventListener('touchend', this.stop);
    },
    stop(e: MouseEvent | TouchEvent): void {
      e.stopPropagation();

      document.removeEventListener('mousemove', this.move);
      document.removeEventListener('touchmove', this.move);
      document.removeEventListener('mouseup', this.stop);
      document.removeEventListener('touchend', this.stop);
    },
    move(e: MouseEvent | TouchEvent): void {
      e.stopPropagation();

      if (this.currentTh == null) return;
      const currentWidth = this.currentOffsetStart + this.getPageX(e);
      if (this.nextTh) {
        const nextWidth = this.nextOffsetStart - this.getPageX(e);
        if (currentWidth >= this.minWidth /*&& nextWidth >= this.minWidth*/) {
          this.setNext(nextWidth);
          this.setCurrent(currentWidth);
        }
      } else {
        if (currentWidth >= this.minWidth) {
          this.setCurrent(currentWidth);
        }
      }
    }
  }
});
