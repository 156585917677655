/**
 * Intl
 *
 * @uses https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl
 */

import vuetify from '@/plugins/vuetify';
import store from '@/store';

export default {
  /**
   * Create Date object
   *
   * @param {string|Date} date
   * @returns {Date}
   */
  createDateObject(date: string|Date): Date {
    let dateObj;
    if (typeof date === 'string') {
      dateObj = new Date(date);
    } else {
      dateObj = date;
    }

    return dateObj;
  },

  /**
   * Normalize locale for app
   *
   * @returns {string}
   */
  normalizeLocale(): string {
    let locale = store.getters.locale;
    if (locale === 'en') {
      locale = 'en-150';
    }

    return locale;
  },

  /**
   * Date formatter for database
   *
   * @param {string|Date} date
   * @returns {string}
   */
  formatDbDate(date: string|Date = ''): string {
    let dateObj: Date = new Date();
    if (date) {
      dateObj = this.createDateObject(date);
    }
    const d = String(dateObj.getDate()).padStart(2, '0');
    const m = String(dateObj.getMonth() + 1).padStart(2, '0');
    const y = dateObj.getFullYear();

    return `${y}-${m}-${d}`;
  },

  /**
   * Date and Time formatter
   *
   * @param {string|Date} date
   * @returns {string}
   */
  formatDateTime(date: string|Date): string {
    return new Intl.DateTimeFormat(this.normalizeLocale(), {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: store.getters.timeZone
    }).format(this.createDateObject(date));
  },

  /**
   * Date formatter
   *
   * @param {string|Date} date
   * @returns {string}
   */
  formatDate(date: string|Date): string {
    return new Intl.DateTimeFormat(this.normalizeLocale(), {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: store.getters.timeZone
    }).format(this.createDateObject(date));
  },

  /**
   * Number formatter
   *
   * @param {Number} nr
   * @returns {string}
   */
  formatNumber(nr: number): string {
    return new Intl.NumberFormat(this.normalizeLocale()).format(nr);
  },

  /**
   * Currency number formatter
   *
   * @param {Number} nr
   * @param {string} currency - Currency code
   * @returns {string}
   */
  formatCurrency(nr: number, currency: string): string {
    return new Intl.NumberFormat(this.normalizeLocale(), {
      style: 'currency',
      currency: currency
    }).format(nr);
  },

  /**
   * Number formatter
   *
   * @param {number | string} nr
   * @param {number} decimals
   * @param {string} decPoint
   * @param {string} thousandsSep
   * @returns {string}
   */
  numberFormat(nr: number | string, decimals: number, decPoint = '.', thousandsSep = ','): string {
    let number = Number(String(nr).replace(/[^0-9+\-Ee.]/g, ''));
    number = !isFinite(number) ? 0 : number;
    const precision: number = !isFinite(decimals) ? 0 : Math.abs(decimals);
    let resultString = String(number);
    const matches = String(number).match(new RegExp('^-?\\d+(?:\.\\d{0,' + precision + '})?'));
    if (matches && matches.length) {
      resultString = matches[0];
    }
    const result: Array<string> = resultString.split('.');
    if (result[0].length > 3) {
      result[0] = result[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, thousandsSep);
    }
    if ((result[1] || '').length < precision) {
      result[1] = result[1] || '';
      result[1] += new Array(precision - result[1].length + 1).join('0');
    }

    return result.join(precision ? decPoint : '');
  },

  /**
   * Get first day of the week
   * Returns 0 for Sunday, 1 for Monday, -1 for Saturday and -2 for Friday
   *
   * @returns {number}
   */
  getFirstDayOfWeek(): number {
    const parts: Array<string> = this.normalizeLocale().split('-');

    const language: string = parts[0];
    const languageSat: Array<string> = ['ar','arq','arz','fa'];
    const languageSun: Array<string> = ['am', 'as', 'bn', 'dz', 'en', 'gn', 'gu', 'he', 'hi', 'id', 'ja', 'jv',
      'km', 'kn', 'ko', 'lo', 'mh', 'ml', 'mr', 'mt', 'my', 'ne', 'om', 'or', 'pa', 'ps', 'sd', 'sm', 'sn', 'su',
      'ta', 'te', 'th', 'tn', 'ur', 'zh', 'zu'];

    const region: string = parts[1];
    const regionSat: Array<string> = ['AE', 'AF', 'BH', 'DJ', 'DZ', 'EG', 'IQ', 'IR', 'JO', 'KW', 'LY', 'OM',
      'QA', 'SD', 'SY'];
    const regionSun: Array<string> = ['AG', 'AR', 'AS', 'AU', 'BD', 'BR', 'BS', 'BT', 'BW', 'BZ', 'CA', 'CN',
      'CO', 'DM', 'DO', 'ET', 'GT', 'GU', 'HK', 'HN', 'ID', 'IL', 'IN', 'JM', 'JP', 'KE', 'KH', 'KR', 'LA', 'MH',
      'MM', 'MO', 'MT', 'MX', 'MZ', 'NI', 'NP', 'PA', 'PE', 'PH', 'PK', 'PR', 'PT', 'PY', 'SA', 'SG', 'SV', 'TH',
      'TT', 'TW', 'UM', 'US', 'VE', 'VI', 'WS', 'YE', 'ZA', 'ZW'];

    let result: number = languageSun.includes(language) ? 0 : languageSat.includes(language) ? -1 : 1;
    if (region) {
      result = languageSun.includes(language) && regionSun.includes(region)
        ? 0
        : languageSat.includes(language) && regionSat.includes(region) ? -1 : 1;
    }

    return result;
  },

  /**
   * Translate by key
   *
   * @param {string} key
   * @param {Array<string | number>} params
   * @returns {string}
   */
  t(key: string, ...params: Array<string | number>): string {
    let result = vuetify.framework.lang.t('$vuetify.' + key, ...params);
    if (result.startsWith('$vuetify.')) {
      result = key;
    }

    return result;
  }
}
