
import Vue from 'vue';

export default Vue.extend({
  name: 'LanguagePanel',
  data() {
    return {
      lang: this.$store.getters.locale,
      languages: {
        'en': 'English',
        'ro': 'Română'
      }
    }
  },
  methods: {
    changeLang(code: string): void {
      this.$store.dispatch('setLocale', code);
      location.reload();
    }
  }
});
