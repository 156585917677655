
import Vue from 'vue';

export default Vue.extend({
  name: 'VDialogContainer',
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
});
