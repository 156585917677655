
import Vue from 'vue';
import intl from '@/utils/intl';
import LanguagePanel from '@/components/language-panel.vue';

export default Vue.extend({
  props: {
    title: String
  },
  components: {
    LanguagePanel
  },
  computed: {
    pageTitle() {
      return intl.t(this.title);
    }
  }
});
