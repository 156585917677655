import type { Commit, Dispatch } from 'vuex';
import type { User } from '@/types/User';
import Http, { HttpError } from '@/utils/Http';

interface State {
  accessTokenType: string
  accessToken: string
  refreshToken: string
  user: User
  logged: boolean
}

export default {
  state: {
    accessTokenType: '',
    accessToken: '',
    refreshToken: '',
    user: {},
    logged: false
  },
  mutations: {
    setAccessToken(state: State, payload: { type: string, token: string }): void {
      state.accessTokenType = payload.type;
      state.accessToken = payload.token;
    },
    setRefreshToken(state: State, token: string): void {
      state.refreshToken = token;
    },
    setLoggedUser(state: State, user: User): void {
      state.user = user;
      state.logged = true;
    },
    clearUserData(state: State): void {
      state.accessTokenType = '';
      state.accessToken = '';
      state.refreshToken = '';
      state.user = {} as User;
      state.logged = false;
    }
  },
  actions: {
    async logIn({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, payload: Record<string, string>): Promise<void> {
      const formData = new FormData();
      formData.append('username', payload.username);
      formData.append('password', payload.password);

      try {
        const result = await (new Http()).post('/v1/account/login', formData);
        commit('setAccessToken', { type: result.data.token_type, token: result.data.access_token });
        commit('setRefreshToken', result.data.refresh_token);
        await dispatch('getLoggedUser');
      } catch (error) {
        throw (error as HttpError).response?.data.error;
      }
    },
    async logOut({ state, commit }: { state: State, commit: Commit }): Promise<void> {
      try {
        await (new Http()).get('/v1/account/logout', {
          params: {
            'refresh_token': state.refreshToken
          }
        });
      } catch {
        //pass
      }
      commit('clearUserData');
    },
    async refreshToken({ state, commit }: { state: State, commit: Commit }, url: string): Promise<void> {
      const rt = state.refreshToken;
      if (rt) {
        const result = await (new Http()).post(url, JSON.stringify({ token: rt }));
        if (result.status === 200) {
          commit('setAccessToken', { type: result.data.token_type, token: result.data.access_token });
          commit('setRefreshToken', result.data.refresh_token);
        }
      }
    },
    async getLoggedUser({ commit }: { commit: Commit }): Promise<void> {
      try {
        const result = await (new Http()).get('/v1/user/info');
        commit('setLoggedUser', result.data);
      } catch {
        //pass
      }
    }
  },
  getters: {
    accessTokenType: (state: State): string => state.accessTokenType,
    accessToken: (state: State): string => state.accessToken,
    refreshToken: (state: State): string => state.refreshToken,
    user: (state: State): User => state.user,
    logged: (state: State): boolean => state.logged
  }
};
