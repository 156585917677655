
import Vue from 'vue';
import { each } from '@/utils/helpers';

export default Vue.extend({
  name: 'UserAvatar',
  props: {
    user: Object,
    color: String,
    size: {
      type: [String, Number],
      default: 24
    }
  },
  computed: {
    avatarColor(): string {
      if (this.color) {
        return this.color;
      }
      return this.stringToColor();
    },
    nameLetters(): string {
      let letters = '';
      each(this.user.name.split(' '), (letter: string) => {
        letters += letter.substring(0, 1).toUpperCase();
      });
      return letters;
    }
  },
  methods: {
    stringToColor(): string {
      const name = this.nameLetters;
      let asciiCode = 0;
      for (let i = 0; i < name.length; i++) {
        asciiCode = name.charCodeAt(i) + (asciiCode << 5) - asciiCode;
      }
      const colorNum = String(asciiCode) + String(asciiCode) + String(asciiCode);
      const num = Math.round(0xffffff * Number(colorNum));
      return 'rgba(' + (num >> 16 & 255) + ', ' + (num >> 8 & 255) + ', ' + (num & 255) + ', 0.8)';
    }
  }
});
