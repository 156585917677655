
import Vue from 'vue';
import { VForm } from 'vuetify/types/custom';
import Http, { HttpError } from '@/utils/Http';
import intl from '@/utils/intl';
import validators from '@/utils/validators';

export default Vue.extend({
  name: 'ChangePasswordModal',
  mixins: [validators],
  data() {
    return {
      modal: false,
      form: true,
      submitLoading: false,
      oldPassword: null,
      newPassword: null,
      retPassword: null,
      oldErrors: [],
      newErrors: [],
      retErrors: []
    }
  },
  computed: {
    modalTitle(): string {
      return intl.t('views.changePassword.title');
    },
    oldPasswordLabel(): string {
      return intl.t('views.changePassword.oldPassword');
    },
    newPasswordLabel(): string {
      return intl.t('views.changePassword.newPassword');
    },
    confirmPasswordLabel(): string {
      return intl.t('views.changePassword.confirmPassword');
    },
    cancelButton(): string {
      return intl.t('cancel');
    },
    okButton(): string {
      return intl.t('ok');
    }
  },
  methods: {
    close(): void {
      (this.$refs.form as VForm).reset();
      this.modal = false;
    },
    async submit(): Promise<void> {
      if ((this.$refs.form as VForm).validate()) {
        this.submitLoading = true;
        const data = {
          old_password: this.oldPassword,
          new_password: this.newPassword,
          confirm_password: this.retPassword
        };
        try {
          let response = await (new Http()).put('/v1/account/change-password', JSON.stringify(data));
          this.$root.$children[0].$children[0].setFlash('success', response.data.message);
          this.close();
        } catch (error) {
          const err = (error as HttpError).response?.data.error;
          if ('old_password' in err) {
            this.oldErrors = err.old_password;
          }
          if ('new_password' in err) {
            this.newErrors = err.new_password;
          }
          if ('confirm_password' in err) {
            this.retErrors = err.confirm_password;
          }
          this.$root.$children[0].$children[0].setFlash('error', err.message);
        }
        this.submitLoading = false;
      }
    }
  }
});
