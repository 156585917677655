
import Vue from 'vue';
import intl from '@/utils/intl';

export default Vue.extend({
  name: 'FilterDate',
  props: {
    type: String,
    formatted: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dateMenu: false,
      icon: 'mdi-magnify',
      modelFilter: null,
      modelFilterFormatted: null,
      modelType: 'eq',
      listData: [
        { key: 'eq', icon: 'mdi-equal', text: intl.t('dataTableFilters.eq') },
        { key: 'lt', icon: 'mdi-less-than', text: intl.t('dataTableFilters.lt') },
        { key: 'gt', icon: 'mdi-greater-than', text: intl.t('dataTableFilters.gt') },
        { key: 'lte', icon: 'mdi-less-than-or-equal', text: intl.t('dataTableFilters.lte') },
        { key: 'gte', icon: 'mdi-greater-than-or-equal', text: intl.t('dataTableFilters.gte') }
      ]
    }
  },
  computed: {
    firstDayOfWeek(): number {
      return intl.getFirstDayOfWeek();
    }
  },
  watch: {
    modelFilter(val) {
      this.modelFilterFormatted = val ? (this.formatted ? intl.formatDate(val) : val) : null;
      this.$emit('filterValueChange', val);
    },
    modelType(val) {
      this.$emit('typeChange', val);
    }
  },
  methods: {
    clear() {
      this.icon = 'mdi-magnify';
      this.modelType = 'eq';
      this.modelFilter = null;
    }
  }
});
