
import Vue from 'vue';
import { VForm } from 'vuetify/types/custom';
import intl from '@/utils/intl';
import validators from '@/utils/validators';
import Http, { HttpError } from '@/utils/Http';

interface RowItem {
  field: string;
  text: string;
  value?: string | number | null;
  _value?: string | number | null;
  _model?: boolean;
}

export default Vue.extend({
  name: 'ProfileModal',
  mixins: [validators],
  data() {
    return {
      modal: false,
      hasChanges: false,
      submitLoading: false,
      fieldErrors: [],
      items: [] as Array<RowItem>
    }
  },
  computed: {
    profileTitle(): string {
      return intl.t('views.profile.title');
    },
    cancelButton(): string {
      return intl.t('cancel');
    },
    saveButton(): string {
      return intl.t('buttons.save');
    }
  },
  watch: {
    modal(status): void {
      if (status) {
        const user = this.$store.getters.user;
        this.items = [
          { field: 'name', text: this.setLabelName('name'), value: user.name },
          { field: 'username', text: this.setLabelName('username'), value: user.username },
          { field: 'position', text: this.setLabelName('position'), value: user.position },
          { field: 'phone', text: this.setLabelName('phone'), value: user.phone }
        ];
      }
    }
  },
  methods: {
    setLabelName(name: string): string {
      return intl.t(`views.users.${name}Label`);
    },
    close(): void {
      if (this.hasChanges) {
        location.reload();
      } else {
        this.modal = false;
      }
    },
    cancel(item: RowItem): void {
      if (item.value != item._value) {
        item.value = item._value;
      }
      item._model = false;
    },
    getForm(key: string): VForm {
      if (Array.isArray(this.$refs[key])) {
        return (this.$refs[key] as Array<VForm>)[0];
      }

      return this.$refs[key] as VForm;
    },
    async submit(item: RowItem): Promise<void> {
      if (this.getForm(`form_${item.field}`).validate()) {
        const data = [{
          field: item.field,
          value: item.value
        }];
        this.submitLoading = true;
        try {
          const response = await (new Http()).patch(`/v1/user/info`, JSON.stringify(data));
          await this.$store.commit('setLoggedUser', response.data);
          this.hasChanges = true;
          item._model = false;
        } catch (error) {
          const err = (error as HttpError).response?.data.error;
          this.$root.$children[0].$children[0].setFlash('error', err.message);
          if ('name' in err) {
            this.fieldErrors = err.name;
          }
          if ('username' in err) {
            this.fieldErrors = err.username;
          }
          if ('position' in err) {
            this.fieldErrors = err.position;
          }
          if ('phone' in err) {
            this.fieldErrors = err.phone;
          }
        }
        this.submitLoading = false;
      }
    }
  }
});
