
import Vue from 'vue';
import intl from '@/utils/intl';

export default Vue.extend({
  data () {
    return {
      dialog: false,
      appName: process.env.VUE_APP_NAME,
      authorName: process.env.VUE_APP_AUTHOR_NAME,
      authorURL: process.env.VUE_APP_AUTHOR_URL,
    }
  },
  computed: {
    dialogTitle(): string {
      return intl.t('dialogs.about.title');
    },
    appVersion(): string {
      return intl.t('dialogs.about.version', process.env.VUE_APP_VERSION);
    },
    moreText(): string {
      return intl.t('dialogs.about.moreText');
    }
  },
  methods: {
    close(): void {
      this.dialog = false;
    }
  }
});
