import type { Commit } from 'vuex';

interface State {
  locale: string;
  timeZone: string;
}

export default {
  state: {
    locale: '',
    timeZone: ''
  },
  mutations: {
    setAppLocale(state: State, locale: string): void {
      state.locale = locale;
    },
    setAppTimeZone(state: State, timeZone: string): void {
      state.timeZone = timeZone;
    }
  },
  actions: {
    setDefaultLocale({ state, commit }: { state: State, commit: Commit }, locale: string): void {
      if (!state.locale) {
        commit('setAppLocale', locale);
      }
    },
    setLocale({ commit }: { commit: Commit }, locale: string): void {
      commit('setAppLocale', locale);
    },
    setTimeZone({ commit }: { commit: Commit }, timeZone: string): void {
      commit('setAppTimeZone', timeZone);
    }
  },
  getters: {
    locale: (state: State): string => state.locale,
    timeZone: (state: State): string => state.timeZone
  }
};
