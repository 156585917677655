
import Vue from 'vue';
import intl from '@/utils/intl';

export default Vue.extend({
  name: 'FilterInputType',
  props: {
    type: String
  },
  data() {
    return {
      icon: 'mdi-magnify',
      fieldType: this.type,
      modelFilter: null,
      modelType: 'c',
      listData: {
        text: [
          { key: 'c', icon: 'mdi-format-underline-wavy', text: intl.t('dataTableFilters.c') },
          { key: 'nc', icon: 'mdi-format-strikethrough-variant', text: intl.t('dataTableFilters.nc') },
          { key: 'sw', icon: 'mdi-contain-start', text: intl.t('dataTableFilters.sw') },
          { key: 'ew', icon: 'mdi-contain-end', text: intl.t('dataTableFilters.ew') },
          { key: 'eq', icon: 'mdi-equal', text: intl.t('dataTableFilters.eq') },
          { key: 'neq', icon: 'mdi-not-equal-variant', text: intl.t('dataTableFilters.neq') }
        ],
        number: [
          { key: 'c', icon: 'mdi-format-underline-wavy', text: intl.t('dataTableFilters.c') },
          { key: 'eq', icon: 'mdi-equal', text: intl.t('dataTableFilters.eq') },
          { key: 'lt', icon: 'mdi-less-than', text: intl.t('dataTableFilters.lt') },
          { key: 'gt', icon: 'mdi-greater-than', text: intl.t('dataTableFilters.gt') },
          { key: 'lte', icon: 'mdi-less-than-or-equal', text: intl.t('dataTableFilters.lte') },
          { key: 'gte', icon: 'mdi-greater-than-or-equal', text: intl.t('dataTableFilters.gte') }
        ]
      }
    }
  },
  watch: {
    modelFilter(val) {
      this.$emit('filterValueChange', val);
    },
    modelType(val) {
      this.$emit('typeChange', val);
    }
  },
  methods: {
    clear() {
      this.icon = 'mdi-magnify';
      this.modelType = 'c';
      this.modelFilter = null;
    }
  }
});
