/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */

/**
 * foreach
 *
 * @param {Array|Object} mixed
 * @param {CallableFunction} iteratee
 */
export function each(mixed: any, iteratee: CallableFunction): void
{
  if (mixed !== null) {
    if (Object.prototype.toString.call(mixed) === '[object Object]') {
      for (const i in mixed) {
        if (iteratee(mixed[i], i) === false) {
          break;
        }
      }
    } else {
      for (let i = 0; i < mixed.length; i++) {
        if (iteratee(mixed[i], i) === false) {
          break;
        }
      }
    }
  }
}

/**
 * empty
 *
 * @param {Array|Object} mixed
 * @returns {boolean}
 */
export function empty(mixed: any): boolean
{
  let undef, key, i, len;
  const emptyValues = [undef, null, false, 0, '', '0'];
  for (i = 0, len = emptyValues.length; i < len; i++) {
    if (mixed === emptyValues[i]) {
      return true;
    }
  }
  if (typeof mixed === 'object') {
    for (key in mixed) {
      if (key in mixed) {
        return false;
      }
    }

    return true;
  }

  return false;
}

/**
 * ucwords
 *
 * @param {string} str
 * @returns {string}
 */
export function ucwords(str: string): string
{
  const result = str.toLowerCase();

  return result.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g, s => s.toUpperCase());
}

/**
 * String formatter
 *
 * @param {string} str
 * @param {any} args
 * @returns {string}
 */
export function stringFormat(str: string, ...args: any[]): string
{
  for (const key in args) {
    str = str.replace(new RegExp("\\{" + key + "\\}", 'gi'), args[key]);
  }

  return str;
}
