
import Vue from 'vue';

export default Vue.extend({
  name: 'FilterSelectOptions',
  props: {
    options: Array,
    value: String,
    text: String
  },
  data() {
    return {
      modelFilter: null
    }
  },
  watch: {
    modelFilter(val) {
      this.$emit('filterValueChange', val);
    }
  }
});
