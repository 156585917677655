import Vue from 'vue';
import VueRouter, { RouteConfig, Route, NavigationGuardNext, RawLocation } from 'vue-router';

import defaultLayout from '@/layouts/default.vue';
import cardLayout from '@/layouts/card.vue';

import store from '@/store';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'dashboard',
    meta: {
      auth: true
    },
    components: {
      layout: defaultLayout,
      content: () => import('@/views/dashboard.vue')
    },
    props: {
      layout: {
        title: 'views.dashboard.title'
      }
    }
  },
  {
    path: '/users',
    name: 'users',
    meta: {
      auth: true
    },
    components: {
      layout: defaultLayout,
      content: () => import('@/views/users.vue')
    },
    props: {
      content: {
        title: 'views.users.title'
      }
    }
  },
  {
    path: '/invoices',
    name: 'invoices',
    meta: {
      auth: true
    },
    components: {
      layout: defaultLayout,
      content: () => import('@/views/invoices.vue')
    },
    props: {
      content: {
        title: 'views.invoices.title'
      }
    }
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      auth: false
    },
    components: {
      layout: cardLayout,
      content: () => import('@/views/login.vue')
    },
    props: {
      layout: {
        title: 'views.login.title'
      }
    }
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    meta: {
      auth: false
    },
    components: {
      layout: cardLayout,
      content: () => import('@/views/forgot-password.vue')
    },
    props: {
      layout: {
        title: 'views.forgotPassword.title'
      }
    }
  },
  {
    path: '/reset-password/:token',
    name: 'resetPassword',
    meta: {
      auth: false
    },
    components: {
      layout: cardLayout,
      content: () => import('@/views/reset-password.vue')
    },
    props: {
      layout: {
        title: 'views.resetPassword.title'
      }
    }
  },
  {
    path: '*',
    redirect: '/'
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// Fix for NavigationDuplicated
/* eslint-disable */
const originalPush = router.push;
router.push = function push(location: RawLocation, onResolve?: any, onReject?: any): any {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);

  return (originalPush.call(this, location) as any).catch((err: any) => {
    if (err.name !== 'NavigationDuplicated')
      throw err;
  });
};
/* eslint-enable */

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
  if (['login'].includes(to.name as string) && store.getters.logged) {
    next({ name: 'dashboard' });
  }
  if (to.matched.some(item => item.meta.auth)) {
    if (store.getters.logged) {
      next();
    } else {
      next({
        name: 'login',
        query: { redirect: to.fullPath }
      });
    }
  } else {
    next();
  }
});

export default router;
