import Vue from 'vue';
import intl from './intl';

export default Vue.extend({
  data() {
    return {
      rules: {
        required(val: string|number, msg = 'validators.fieldRequired'): boolean | string {
          return !!val || intl.t(msg);
        },
        requiredArray(val: Array<unknown>, msg = 'validators.fieldRequired'): boolean | string {
          return !!val.length || intl.t(msg);
        },
        email(val: string, msg = 'validators.invalidEmailAddress'): boolean | string {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

          return pattern.test(val) || intl.t(msg);
        }
      }
    }
  }
});
