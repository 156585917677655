import { en } from 'vuetify/lib/locale';

export default {
  ...en,
  ok: 'OK',
  cancel: 'Cancel',
  dataTable: {
    ...en.dataTable,
    columnSelector: 'Column selector'
  },
  dataTableFilters: {
    c: 'Contains',
    nc: 'Does not contain',
    sw: 'Starts with',
    ew: 'Ends with',
    eq: 'Equal',
    neq: 'Not equal',
    lt: 'Less than',
    gt: 'Greater than',
    lte: 'Less than or equal',
    gte: 'Greater than or equal'
  },
  validators: {
    fieldRequired: 'Field is required!',
    invalidEmailAddress: 'Invalid email address!'
  },
  general: {
    noConnection: 'Cannot connect to the service!',
    logout: 'Logout',
    backTo: 'Back to {0}'
  },
  buttons: {
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    save: 'Save',
    send: 'Send',
    filters: 'Filters',
    export: 'Export',
    import: 'Import',
    download: 'Download',
    downloadAll: 'Download All',
    close: 'Close'
  },
  statuses: {
    active: 'Active',
    inactive: 'Inactive',
    yes: 'Yes',
    no: 'No',
    open: 'Open',
    closed: 'Closed'
  },
  filters: {
    apply: 'Apply',
    reset: 'Reset',
    dateSuffixBegin: 'start',
    dateSuffixEnd: 'end'
  },
  dialogs: {
    about: {
      title: 'About',
      version: 'Version {0}',
      moreText: 'All rights reserved.'
    }
  },
  views: {
    dashboard: {
      title: 'Dashboard',
      receivedInvoicesLabel: 'Received invoices',
      issuedInvoicesLabel: 'Issued invoices',
      issuedInvoicesErrorsLabel: 'Issued invoices errors',
      messagesLabel: 'Messages',
      usersLabel: 'Users',
      totalLabel: 'Total',
      prevMonthLabel: 'Previous month',
      currentMonthLabel: 'Current month',
      adminsLabel: 'Administrators',
      operatorsLabel: 'Operators'
    },
    login: {
      title: 'Authentication',
      button: 'Authenticate',
      username: 'Username',
      password: 'Password'
    },
    forgotPassword: {
      title: 'Forgot password',
      button: 'Send',
      successMessage: 'To reset the password please follow the steps from the email you received.'
    },
    resetPassword: {
      title: 'Reset password',
      button: 'Reset password',
      newPassword: 'New password',
      confirmPassword: 'Confirm password'
    },
    profile: {
      title: 'Profile'
    },
    changePassword: {
      title: 'Change password',
      oldPassword: 'Old password',
      newPassword: 'New password',
      confirmPassword: 'Confirm password'
    },
    users: {
      title: 'Users',
      createText: 'User "{0}" added with success.',
      updateText: 'User "{0}" modified with success.',
      deleteText: 'User "{0}" was deleted.',
      confirmDeleteTitle: 'Delete User',
      confirmDeleteText: 'Are you sure you want to delete the user "{0}"?',
      nameLabel: 'Name',
      usernameLabel: 'Username',
      passwordLabel: 'Password',
      roleLabel: 'Role',
      positionLabel: 'Position',
      phoneLabel: 'Phone',
      statusLabel: 'Status',
      tenantLabel: 'Tenant',
      roles: {
        sa: 'Super Admin',
        admin: 'Administrator',
        operator: 'Operator'
      }
    },
    invoices: {
      title: 'Invoices',
      tabs: {
        issuedInvoices: 'Issued invoices',
        receivedInvoices: 'Received invoices',
        receivedMessages: 'Received messages'
      },
      statuses: {
        not_processed: 'Not precessed',
        processing: 'Processing',
        error: 'Error',
        processed_ok: 'Processed'
      },
      resend: {
        tooltip: 'Resend to ANAF',
        confirmTitle: 'Are you sure you want to resend?',
        confirmText: 'Your invoice will be sent again to ANAF.'
      },
      export: {
        nameLabel: 'File name',
        allPagesLabel: 'All pages',
        currentPageLabel: 'Current page ({0})',
        startDateLabel: 'From date',
        stopDateLabel: 'To date',
        exportOptionsLabel: 'Export options',
        selectPeriodLabel: 'Select period'
      },
      import: {
        templateFileButton: 'Template file',
        fileLabel: 'File',
        importedSuccessMessage: 'File imported with success.'
      },
      logs: {
        title: 'Logs {0}',
        idUploadLabel: 'ID upload',
        idDownloadLabel: 'ID download',
        statusLabel: 'Status',
        errorMessageLabel: 'Error message',
        lastUpdateLabel: 'Last update',
        anafStatusLabel: 'ANAF status',
        anafResponseLabel: 'ANAF response'
      },
      associatedFilesMenuItem: 'View associated files',
      logsMenuItem: 'View logs',
      savedText: 'Invoice "{0}" saved with success.',
      filesTitle: 'Invoice {0} files',
      invoiceNumberLabel: 'Invoice number',
      issueDateLabel: 'Issue date',
      dueDateLabel: 'Due date',
      issuerNameLabel: 'Issuer',
      issuerVatNumberLabel: 'Issuer VAT number',
      issuerCompanyNameLabel: 'Issuer Company Name',
      recipientNameLabel: 'Recipient',
      recipientVatNumberLabel: 'Recipient VAT number',
      recipientCompanyNameLabel: 'Recipient Company Name',
      idUploadLabel: 'ID upload',
      idDownloadLabel: 'ID download',
      statusLabel: 'Status',
      errorMessageLabel: 'Error message',
      valueWithoutVatLabel: 'Value without VAT',
      valueVatLabel: 'VAT value',
      valueWithVatLabel: 'Value with VAT',
      lastUpdateLabel: 'Last update',
      typeLabel: 'Type',
      idRequestLabel: 'ID request',
      detailsLabel: 'Details',
      messageLabel: 'Message',
      dateLabel: 'Date'
    },
    files: {
      nameLabel: 'Name',
      typeLabel: 'Type',
      descriptionLabel: 'Description'
    }
  }
};
