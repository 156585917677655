
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import type { PropType } from 'vue/types/options';
import type { Header } from '@/types/DataTable';
import { each } from '@/utils/helpers';

import FilterInputType from '@/components/data-table/filter-input-type.vue';
import FilterSelectOptions from '@/components/data-table/filter-select-options.vue';
import FilterDate from '@/components/data-table/filter-date.vue';

interface Filter {
  type: string;
  value: string | number | null;
  header_value: string;
  filter_type: string;
}

export default Vue.extend({
  name: 'DataTableFilters',
  components: {
    FilterInputType,
    FilterSelectOptions,
    FilterDate
  },
  props: {
    showSelect: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    headers: {
      type: Array as PropType<Header[]>,
      default: () => []
    }
  },
  data() {
    return {
      filters: {} as Record<string, Filter>
    }
  },
  methods: {
    filterText(type: string, from: string, to: string): boolean {
      let result: boolean;
      if (from == null) {
        from = '';
      }
      if (to == null) {
        to = '';
      }
      from = from.toLocaleLowerCase();
      to = to.toLocaleLowerCase();
      switch (type) {
        default:
        case 'c':
          result = from.includes(to);
          break;
        case 'nc':
          result = !from.includes(to);
          break;
        case 'sw':
          result = from.startsWith(to);
          break;
        case 'ew':
          result = from.endsWith(to);
          break;
        case 'eq':
          result = from == to;
          break;
        case 'neq':
          result = from != to;
          break;
      }

      return result;
    },
    filterNumber(type: string, from: number, to: number): boolean {
      let result: boolean;
      switch (type) {
        default:
        case 'c':
          result = String(from).includes(to != null ? String(to) : '');
          break;
        case 'eq':
          result = from == to;
          break;
        case 'lt':
          result = from < to;
          break;
        case 'gt':
          result = from > to;
          break;
        case 'lte':
          result = from <= to;
          break;
        case 'gte':
          result = from >= to;
          break;
      }

      return result;
    },
    filterBoolean(from: any, to: any): boolean {
      const type = (val: any): number => {
        let result = val;
        if (typeof val === 'string') {
          result = val == '' ? 0 : Number(val);
        }
        if (val === true) {
          result = 1;
        }
        if (val === false) {
          result = 0;
        }

        return result;
      };

      return type(from) == type(to);
    },
    filterDate(type: string, from: string, to: string): boolean {
      let result = false;
      if (from && to) {
        const fromDate = new Date(from);
        const toDate = new Date(to);
        switch (type) {
          default:
          case 'eq':
            result = fromDate.getTime() == toDate.getTime();
            break;
          case 'lt':
            result = fromDate.getTime() < toDate.getTime();
            break;
          case 'gt':
            result = fromDate.getTime() > toDate.getTime();
            break;
          case 'lte':
            result = fromDate.getTime() <= toDate.getTime();
            break;
          case 'gte':
            result = fromDate.getTime() >= toDate.getTime();
            break;
        }
      }

      return result;
    },
    makeFilterName(name: string): string {
      return name.replaceAll('.', '_');
    },
    getItemValue(item: any, field: string): any {
      return field.split('.').reduce((accumulator: any, currentValue: string) => accumulator[currentValue], item);
    }
  },
  watch: {
    headers: {
      handler(data: Array<Header>): void {
        // Create filters
        const filters: Record<string, Filter> = {};
        each(data, (header: Header) => {
          if (header.dtFilter && header.dtFilter.type) {
            filters[this.makeFilterName(header.value)] = {
              type: '',
              value: null,
              header_value: header.value,
              filter_type: header.dtFilter.type === 'select'
                ? (header.dtFilter.optionType || 'text')
                : header.dtFilter.type
            };
          }
        });
        this.filters = filters;
      },
      immediate: true
    },
    items: {
      handler(data: Array<any>): void {
        this.$emit('filterResult', data);
      },
      immediate: true
    },
    filters: {
      handler(newFilter: Record<string, Filter>): void {
        let result = [...this.items];
        each(newFilter, (data: Filter) => {
          if (data.value !== null) {
            result = result.filter((item: any) => {
              let result = false;
              switch (data.filter_type) {
                case 'boolean':
                  result = this.filterBoolean(item[data.header_value], data.value);
                  break;
                case 'number':
                  result = this.filterNumber(data.type, this.getItemValue(item, data.header_value), Number(data.value));
                  break;
                case 'text':
                  result = this.filterText(data.type, this.getItemValue(item, data.header_value), String(data.value));
                  break;
                case 'date':
                  result = this.filterDate(data.type, item[data.header_value], String(data.value));
                  break;
              }
              return result;
            });
          }
        });
        this.$emit('filterResult', result);
      },
      deep: true
    }
  }
});
