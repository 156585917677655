
import Vue from 'vue';
import intl from '@/utils/intl';

export default Vue.extend({
  name: 'App',
  watch: {
    $route(to) {
      let title = [];
      if (
        Object.keys(to.matched[0].props).length
        && 'layout' in to.matched[0].props
        && 'title' in to.matched[0].props.layout
      ) {
        title.push(intl.t(to.matched[0].props.layout.title));
      }
      if (
        Object.keys(to.matched[0].props).length
        && 'content' in to.matched[0].props
        && 'title' in to.matched[0].props.content
      ) {
        title.push(intl.t(to.matched[0].props.content.title));
      }
      title.push(process.env.VUE_APP_NAME);
      document.title = title.join(' - ');
    }
  },
  mounted() {
    this.$store.dispatch('setDefaultLocale', process.env.VUE_APP_LOCALE);
    this.$store.dispatch('setTimeZone', process.env.VUE_APP_TIMEZONE);
    this.$vuetify.lang.current = this.$store.getters.locale;
    document.querySelector('html')?.setAttribute('lang', this.$store.getters.locale);
  }
});
